::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #b6b6b6;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
/* .App {
  text-align: center;
} */
/* .App.pc {
  zoom: .8;
} */
.App.sp {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.App.sp .allWrapper {
  height: calc(100vh - 58px);
}
.App.sp .onlyAlert + .allWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 58px - 26px);
}
.App.sp .home + .allWrapper {
  overflow-y: auto;
  height: calc(100vh - 58px - 80px);
}
.App.sp .allFooter {
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nowrap {
  white-space: nowrap;
}

.MuiButton-root {
  /* uppercaseの上書き */
  text-transform: unset !important;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} 
input[type="number"] { 
  -moz-appearance: textfield; 
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}
